<template>
  <v-card class="mx-auto mt-5" max-width="500" :loading="loadingLogin">
    <v-card-title>
      <v-img
        contain
        max-width="300"
        src="../assets/logo-300.png"
        class="mx-auto"
      >
      </v-img>
    </v-card-title>

    <br />

    <h2 class="text-center">
      Iniciar sesión
    </h2>

    <br />

    <v-divider></v-divider>

    <v-card-text>
      <v-text-field label="Usuario" v-model="loginData.usuario"></v-text-field>

      <v-text-field
        v-model="loginData.passwd"
        :append-icon="showPasswd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPasswd ? 'text' : 'password'"
        name="input-10-1"
        label="Contraseña"
        @click:append="showPasswd = !showPasswd"
      ></v-text-field>
    </v-card-text>

    <v-card-text v-show="alerta.mostrar">
      <v-alert
        dismissible
        transition="scale-transition"
        v-model="alerta.mostrar"
        :type="alerta.tipo"
      >
        {{ alerta.mensaje }}
      </v-alert>
    </v-card-text>

    <v-card-actions>
      <v-btn color="green" @click="login">
        Iniciar sesión
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      loadingLogin: false,
      showPasswd: false,
      alerta: {
        mostrar: false,
        tipo: null,
        mensaje: null,
      },
      loginData: {
        usuario: '',
        passwd: '',
      },
    };
  },
  methods: {
    async login() {
      try {
        this.alerta = {
          mostrar: false,
          tipo: null,
          mensaje: null,
        };

        this.loadingLogin = true;

        await axios.post(process.env.VUE_APP_API_URL + '/usuarios/login', this.loginData);

        this.loadingLogin = false;

        this.$router.push({ name: 'Inicio' });
      } catch (error) {
        this.loadingLogin = false;

        this.alerta = {
          mostrar: true,
          tipo: 'error',
          mensaje: error.response.data,
        };
      }
    },
  },
  mounted() {
    this.$emit('usePanel', false);
  },
};
</script>

<style>
#app {
  background-image: url(https://alistelab.com/wp-content/uploads/2018/10/slider_mini_elements-1024x499.png);
  background-repeat: repeat;
}
</style>
